.carousel .slider-wrapper {
  height: 500px;
}
.carousel .slider.animated {
  height: 100%;
}
@media only screen and (max-width: 600px) {
  .carousel .slider-wrapper {
    height: 100%;
  }
}
